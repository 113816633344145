import Axios from "./AxiosClass";
import store from "../store/store";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import BearerTokenManager from "./BearerTokenManager";
import config from "../config";

/**
 * @class AxiosBuilder
 * @classdesc
 * Creates an instance of Axios with the default configuration.
 **/
export class AxiosBuilder {
    constructor(config = {}) {
        this.config = config;
    }

    /**
     * @method build
     * @return {Axios}
     * @description
     * Get Axios config object values asynchronously from fingerprintjs and Action api
     * Then creates custom Axios client
     **/
    async build() {
        // functions are allowed to run async => grouped

        if(config.allowMaintenanceCheck){
            await Promise.all([this.withMaintenanceCheck(), this.withBearerToken()]);
        }else{
            await Promise.all([this.withBearerToken()]);
        }
        await Promise.all([this.withFingerprint()]);
        return new Axios(this.config);
    }

    /**
     * @method withFingerprint
     * @return {Promise<string>}
     * @description
     * Get fingerprint visitorId from FingerprintJS and saves it to store
     **/

    async withFingerprint() {
        return (
            await FingerprintJS.load({
                token: "PZ2B2o9KoKgKPsIAoOjH",
                region: "eu",
            })
            )
        .get()
        .then((fingerprint) => {
            store.commit("SET_FINGERPRINT", fingerprint.visitorId);
        })
        .catch(() => {
            //axios is not available at this level
            fetch(config.apiUrl + '/fingerprint/' + config.idlead, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((resp) => {
                return resp.json()
            })
            .then((fingerprint) => {
                store.commit("SET_FINGERPRINT", fingerprint)
            })
            .catch(err => console.log('err' + err))
        });
    }

    /**
     * @method withBearerToken
     * @return {Promise}
     * @description
     * Get Bearer token from BearerTokenManager and saves it to store
     **/
    async withBearerToken() {
        const token = await new BearerTokenManager().getNewTokenFromApi();
        this.config.customHeaders.Authorization = `Bearer ${token.accessToken}`;
    }

    async withMaintenanceCheck() {
        return new Promise((resolve) => {
            fetch(config.apiUrl + '/health', {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(async (res) => {
            //api is available
                if(res.status === 503){
                 store.dispatch("setMaintenance", true);
             }else if(res.status === 400) {
                let status = await res.text()
                if(status === "maintenance") {
                    store.dispatch("setMaintenance", true);
                }
            } else {
                store.dispatch("setMaintenance", false);
            }
            resolve();
        })
        })
    }


} /* End export class */
