export default {
  methods: {
    putMatomoScriptInPlace(){
      console.log("===== MATOMO SCRIPT =====");
      let script = document.createElement('script');

      script.innerHTML = `
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="https://promolife.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '6']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='//cdn.matomo.cloud/promolife.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
      })();
      `;
      // document.body.prepend(script);
      document.head.appendChild(script);
    }
  },
  beforeMount() {
    this.putMatomoScriptInPlace();
  }
}




