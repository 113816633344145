export default {
    data () {
        return {

            /* For CLIENT in GDPR, see the translation files */

            /* SCRIPTS */
            thereIsFloodLightScriptInThisAction: false,

            /* Breadcrumb content */
            /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
            listOfCrumbsForThisAction: ['store', 'form', 'cashback'],
            /* TODO : associer les noms et les étapes ? */
           /*
            listOfStepsForThisAction: [
            {
                    stepnumber: 1,
                    icon: 'info'
                }, {
                    stepnumber: 1,
                    icon: 'register'
                }
                ]
            }
            */


            /* ?  Is there the couple ask-survey + survey pages that allow a coupon in the mail ?*/
            /* ! I cannot simply check for metadata in the lead as they can appear on the form page */
            thereIsASurveyInThisAction: false, 

            /* ? The list of fields that must be valid for a specific button to unlock */
            /* ! As the disposition of fields on the page is only front-end relevant, I cannot get the info from the lead */
            mustBeValidForPage: {
                /* button on the FORM page */
                'btn_cashback_on_form1' : ['idTitle', 'firstName', 'lastName', 'email', 'IBAN', 'street1', 'houseNumber', 'postalCode', 'city',],
                'btn_cashback_on_form2' : ['selectProduct', 'file', 'conditions'],
                /* button on the SURVEY page */
                'btn_cashback_on_survey': ['idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3'],
            },

            // ! Maybe I can compose this programmatically ;)
            allRequiredFields: this.getFullListOfRequiredFields(),

        }
    },
    methods: {

        getFullListOfRequiredFields(){
            /* TODO write this function */
            /* Can I get the info from the LEAD? */
            /* Not completely : some fields are optionnal (field B is required if field A has value X) */
            let fullList = [];
            /* TODO this could be automated : mustBeValidForPage.btn_cashback_on_form1 + mustBeValidForPage.btn_cashback_on_form2 */
            fullList.push('firstName', 'lastName', 'email', 'IBAN', 'selectProduct', 'file', 'conditions', 'idTitle');
            /* ! It seem I can't write the code to get it from this.mustBeValidForPage :/ */
            /* BASE FIELDS */
            return fullList;
            /* SURVEY FIELDS (if surveyMustBeFilled) */
        },

    },
}
