/* Custom Console.log to display nice messages ONLY on dev. and acceptance stage. */
import config from "../config";

export default {
	data() {return {
		isCustomConsoleAllowed : config.isAdminNavAllowed, /* required for customLog */
	}},

	methods: {

		testAllLogs(){
			/* One function to test them all */
			this.log('LEGEND for all custom logs styles', 'group');

			this.log("LEGEND for all custom logs styles", 'title');
			this.log('This is a neutral log');
			this.log("Information", "sep");
			this.log('This is an info', 'info');
			this.log("Low importance info", 'low');
			this.log('This is an API call function', 'api');
			this.log("Everything is right", 'success');
			this.log('This is a truthy value', 'green');
			this.log("Something is wrong", 'alert');
			this.log('This is a falsy value', 'red');
			this.log("Working on something?", "sep");
			this.log("Work in progress", 'wip');		
			this.log("Point that require attention", 'puce');	
			this.log("Something to do", 'todo');
			this.log("Function name", 'function');
			this.log('I thought this stuff was obsolete, but I see it in the log!', 'obsolete');
			this.log(["a", "b", 12], 'table');
			this.log('', 'groupEnd');
		},

		log(str, type='neutral'){
			/* The different styles are: neutral, info, low, title, group, groupEnd, alert, success, todo, wip, puce, table, api, obsolete, sep, green, red */
			if ( this.isCustomConsoleAllowed ){
				if (type === 'neutral'){
					console.log(str);
				}

				if (type === 'api'){
					console.log('%cAPI Call%c ' + str, 'display: inline-block ; background:#8f04d0; color:#fff; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if (type === 'function'){
					console.log('%cFunction%c ' + str, 'display: inline-block ; background:#db8436; color:#fff; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if (type === 'table'){
					console.table(str);
				}

				if (type === 'obsolete'){
					console.log('%cObsolete%c ' + str, 'display: inline-block ; background:#aaaaaa; color:#fff; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if (type === 'alert' || type ==='error'){
					console.log('%cAttention%c ' + str, 'display: inline-block ; background:#cc3300; color:#fff; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if (type === 'success'){
					console.log('%cCool!%c ' + str, 'display: inline-block ; background:#0c0; color:#fff; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if (type === 'wip'){
					console.log('%cWIP%c ' + str, 'display: inline-block ; background:#fc0; color:#000; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if (type === 'puce'){
					console.log("%c %c " + str, "background: #fc0; padding:0 0.35em; border-radius: 50px;", "color:#666;");
				}

				if (type === 'todo'){
					console.log('%cToDo%c ' + str, 'display: inline-block ; background:#ed72e3; color:#fff; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if (type === 'info'){
					console.log('%cInfo%c ' + str,  'display: inline-block ; background:#2592F6; color:#fff; border-radius:4px; padding:0.25em 0.5em;', 'color:#000;');
				}

				if(type === 'low'){
					console.log('%c ' + str, 'color:#ccc;');
				}

				if(type === 'green'){
					console.log('%c %c ' + str, "background: #0a0; padding:0 0.35em; border-radius: 50px;", 'color:#0a0;');
				}

				if(type === 'red'){
					console.log('%c %c ' + str, "background: #c00; padding:0 0.35em; border-radius: 50px;", 'color:#c00;');
				}

				if(type === 'title'){
					let strLen = str.length;
					let longueur = 60-strLen;
					console.log("-".repeat(str.length + longueur + 4));
					console.log(" ".repeat(longueur/2 +1) + str + " ".repeat(longueur/2 +1));
					console.log("-".repeat(str.length + longueur + 4));
				}

				if (type === 'sep'){
					let strLen = str.length;
					let longueur = 60-strLen;
					console.log("%c   " + str + " ".repeat(longueur), "background: #2592F6; color: #fff; padding:4px; border-radius:4px;");
				}

				if (type === 'group'){
					console.groupCollapsed("%cGROUP: " + str, 'display: inline-block ; background:#ccf; color:#000; border-radius:4px; padding:0.25em 1em;');
				}

				if (type === 'groupEnd'){
					console.groupEnd();
				}
			}
		}    
	}

}