<template>
	<div class="aCustomSelect field">

		<label  :for="fieldname">{{label}}<span v-if="isRequired" class="req">&nbsp;*</span></label>

		<div class="dropdown" :class="{'open': isListOpen}">
			<input
			type="text"
			autocomplete="off"
			:id="fieldname"
			:placeholder="placeholder"
			v-model="textInField"
			@input="userInputChanged"
			@keyup.prevent.down="down"
			@keyup.prevent.up="up"
			@keyup.prevent.enter="enter"
			@focus="turnAutoFillOff"
			@blur="turnAutoFillOn"
			:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
			:disabled="isDisabled"
			>

			<!-- Toggler -->
			<div class="toggle" @click="displaySuggestions(!isListOpen)" v-if="!isDisabled">
				<span class="arrow-up" v-if="isListOpen">▲</span>
				<span class="arrow-down" v-if="!isListOpen">▼</span>
			</div>

			<!-- Suggestions list -->
			<ul class="suggestion-list">
				<li 
				:class="{'active': currentActiveSuggestion === index}" 
				v-for="(suggestion, index) in matches" 
				:key="index"
				@click="selectSuggestion(suggestion, index)"
				>
				{{suggestion[1][description]}}
			</li>
		</ul>

	</div>

	<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
	<div class="helper">{{helper}}</div>

</div>
</template>

<!-- ================================================================================== -->

<script>
	import FormFields from "../mixins/FormFields";
	import config from "../config";

	export default {
		name:"customSelect",

		mixins: [FormFields],

		props : {
			label : {
				type: String,
				required: true
			},
			isDisabled: {
				type: Boolean,
				required: false,
			default: false
			},
			dataFrom : {
				type: String,
				required: true
			},
			identifier : {
				type: String,
				required: true
			},
			description : {
				type: String,
				required: true
			},
			modelValue: {
				required: false,
			},
			isReparticipation: {
				type: Boolean,
				required: true,
			}
		},

		data(){
			return {
				isListOpen : false,
				options : [],
				userInput : "",
				config: config,
				currentActiveSuggestion : 0, // not really reliable when one types the value, without using the list.
				errorMessage : '',
				autoFill : true,
			}
		},

		computed: {
			matches() {
				return Object.entries(this.options).filter((option) => {
					let optionText = option[1][this.description].toUpperCase();
					return optionText.match(this.userInput.toUpperCase());
				});
			},

			textInField: {
				get(){
					let receivedId = this.modelValue;
					let categories = Object.keys(this.options);
					let description;
					// console.group("OPTIONS"); 
					// console.log(this.options);
					categories.forEach(() =>{
						let result = this.options.find(obj => {
							return obj[this.identifier] == receivedId;
						});
						if(result){
							description = result[this.description];
						}
					});
					// console.log("--> " + description);
					// console.groupEnd();
					return description;
				},
				set(){
					// console.log("reset de textInField");
					this.userInput = "";
					return "";
				}
			}

		},

		watch: {
			/* Watch locale, not lang, as nl_BE is sometimes different from nl_NL (and fr_BE could be different from fr_LU) */
			'$store.getters.getLocale': function( /* newLocale, oldLocale */ ){
				// console.log(oldLocale + " --> " + newLocale);
				/* Must trigger a refresh of data from here */
			this.getSuggestionsFromAPI();
		},
	},

	methods: {

		turnAutoFillOff(){
			this.autoFill = false;
		},

		turnAutoFillOn(){
			this.autoFill = true;
		},

		getSuggestionsFromAPI() {
			this.setLoading();
			this.$store.commit('SET_LOCALE',this.$i18n.locale.substring(0,2)); 
			this.axios.get(`/dropdown/${config.idlead}/${this.dataFrom}`) 
			.then(resp => {
				this.apiForDropdownIsSuccessful(resp.data);
			})
			.catch(error => {
				if(error.response.status === 503){
					this.setNotLoading();
					this.$emit('maintenanceDetected');
				}else{
					console.log("Cannot get list from API");
					console.error(error);
				}
			})
			.then(()=>{
					// console.log("List from API are put in place in " + this.fieldname);
			});
			this.setNotLoading();
		},

		apiForDropdownIsSuccessful(data){
				/* attention, si dataFrom === "countries-allowed" il y a un tri de plus à faire */
			let self = this;
			this.options = data.filter(function (item){
				if(self.dataFrom === "countries-allowed"){
					return item[self.description] != '' && item[self.description] != null && item.countrytypereference.code === "ConsumerAddress";
				}else{
					return item[self.description] != '' && item[self.description] != null;
				}					
			});
		},

		displaySuggestions(bool){
				// bool true: open list | false: close list
			if(bool){
					// console.log("Open the list? " + bool);
				this.removeText();
				this.openList();
			}else{
				this.closeList();
			}
		},

		userInputChanged() {
			if (!this.isListOpen) {
				this.openList();
			}
		},

		openList () {
			this.isListOpen = true;
			this.removeText();
		},

		closeList () {
			this.isListOpen = false;
		},

		removeText(){
			this.currentActiveSuggestion = '';
			this.userInput = "";
			this.textInField = "";
		},

		selectSuggestion (suggestion, index) {
			this.closeList();
			this.userInput = suggestion[1][this.description];
			this.$emit('update:modelValue', suggestion[1][this.identifier]);
			this.currentActiveSuggestion = index;
			this.checkIfImValid();
		},

		up() {
			if(this.isListOpen && this.currentActiveSuggestion > 0){
				this.upOneItem();
			}else if(this.isListOpen){
				this.displaySuggestions(false);
				this.currentActiveSuggestion = 0;
			}
		},

		down() {
			if(this.isListOpen && this.currentActiveSuggestion < this.matches.length-1){
				this.downOneItem();
			}else if(!this.isListOpen){
				this.displaySuggestions(true);
				this.currentActiveSuggestion = 0;
			}
		},

		downOneItem() {
			if (this.currentActiveSuggestion < this.matches.length-1){
				this.currentActiveSuggestion += 1;
			}
		},

		upOneItem() {
			if(this.currentActiveSuggestion > 0){
				this.currentActiveSuggestion -= 1;
			}
		},

		setSelection(data){
			let itemCode = data[1][this.identifier];
			this.userInput = data[1][this.description];
			this.$emit('update:modelValue', itemCode);
			this.checkIfImValid();
		},

		setFieldInError(msg="Entry is invalid"){
			this.errorMessage = msg;
			this.checkIfImValid();
		},

		enter() {
			if(!this.matches[this.currentActiveSuggestion]){
					this.setFieldInError("Please choose an entry in the list"); // ??? is this ever used ? Yes, but overwritten by a checkIfImValid.
				}else{
					this.setSelection(this.matches[this.currentActiveSuggestion]);
				}

				this.displaySuggestions(false);
			},

			isThisFieldValid(){
				/* What is a valid answer ? An answer that is exactly in the list */
				let listSuggestions = [];
				for(let item in this.options){
					listSuggestions.push(this.options[item][this.description].toLowerCase());
				}
				return listSuggestions.includes(this.userInput.toLowerCase());
			},

			checkIfImValid(){
				// console.log("I'm checking the validity of this field");
				if(this.isThisFieldValid()){
					// console.log("This field " + this.fieldname + " IS valid.");
					this.giveSuccess();
				}else{
					// console.log("This field " + this.fieldname + " IS NOT valid.");
					this.giveError();
				}
				this.sendCheckSignal(this.isThisFieldValid());
				return this.isThisFieldValid();
			},

			sendCheckSignal (bool=false){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},

			sendDataToParent(){
				// console.log("I send modelValue " + this.modelValue + " to parent");
				this.$emit('update:modelValue', this.modelValue);
				// as any answers is good here
				this.giveSuccess();
				this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
			},


		},

		created() {
			this.getSuggestionsFromAPI();
			if(this.isReparticipation){
				this.sendDataToParent();
			}
		},

		updated() {
			/*
			if(this.modelValue && this.userInput.trim() === "" && this.autoFill) {
				let options = this.options.filter(el => el[this.identifier] === this.modelValue);
				let option = options[0];
				this.userInput = option[this.description];
				this.sendCheckSignal(this.checkIfImValid());
			}
			*/
		}

	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

	.dropdown {
		display: inline-block;
		position: relative;
	}

	.suggestion-list {
		background-color: rgba(255, 255, 255, 0.95);
		border: 1px solid #ddd;
		list-style: none;
		display: block;
		margin: 0;
		padding: 0.5em;
		width: 100%;
		overflow: hidden;
		overflow-y:auto;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 30;
		max-height:15em;
	}

	.dropdown .suggestion-list {
		display: none;
	}

	.dropdown.open .suggestion-list {
		display: block;
	}

	.toggle {
		position: absolute;
		top:50%;
		transform:translateY(-50%); /* Visual result seems to be font-dependent */
		right:1em;
		cursor: pointer;
	}

	.toggle .arrow-up {
		display: none;
	}

	.open .toggle .arrow-up {
		display: inline-block;
	}

	.open .toggle .arrow-down {
		display: none;
	}

	.suggestion-list li {
		cursor: pointer;
		padding:0.25em;
	}

</style>
