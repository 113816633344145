export default {
    apiUrl: process.env.VUE_APP_BACKENDURL,
    idlead: process.env.VUE_APP_IDLEAD,
    assetPath: process.env.VUE_APP_ASSETSPATH,
    whatsappNumber: process.env.VUE_APP_WHATSAPPNUMBER,
    clientId: process.env.VUE_APP_CLIENT_ID,
    clientSecret: process.env.VUE_APP_CLIENT_SECRET,
    /* Those below must be converted to Boolean type */
    allowMaintenanceCheck: convertToBool(process.env.VUE_APP_ALLOWMAINTENANCECHECK),
    isAdminNavAllowed: convertToBool(process.env.VUE_APP_ISADMINNAVALLOWED),
    takeOfferStatusIntoAccount: convertToBool(process.env.VUE_APP_TAKEOFFERSTATUSINTOACCOUNT),
    isWebsiteInProduction: convertToBool(process.env.VUE_APP_WEBSITEINPRODUCTION),
    isFullTestPanelAllowed: convertToBool(process.env.VUE_APP_ISFULLTESTALLOWED),
    forceMaintenanceInCaseOfProblem: convertToBool(process.env.VUE_APP_FORCEMAINTENANCE),
}

function convertToBool(val) {
    let newVal = false;
    if(val != null && val != undefined){
        newVal = (val.toLowerCase() === 'true');
    }
    return newVal;
}